:root {
    --text-background: #e5f0ff;
    --text: black;
    --page-backgound: white;
    --header: white;
    --header-background: #444547;
    --card-background: #e5f0ff;
    --border: #cad0d7;
    --correct: black;
    --correct-background: #dff4d8;
    --incorrect: black;
    --incorrect-background: #fcdcdc;
    --comment: black;
    --comment-background: #fcefdc;
    --sidebar: white;
    --input: white;
    --input-background: #606162;
  }
  
  [data-theme='nord-dark'] {
    --text-background: #2e3440;
    --text: #eceff4;
    --page-background: #3b4252;
    --header: #eceff4;
    --header-background: #2e3440;
    --card-background: #2e3440;
    --border: #2e3440;
    --correct: #2e3440;
    --correct-background: #a3be8c;
    --incorrect: #2e3440;
    --incorrect-background: #bf616a;
    --comment: #2e3440;
    --comment-background: #ebcb8b;
    --sidebar: #eceff4;
    --input: #e5e9f0;
    --input-background: #4c566a;
  }
  
  [data-theme='mocha-dark'] {
    --text-background: #322a22;
    --text: #d0c8c6;
    --page-background: #3b3228;
    --header: #eceff4;
    --header-background: #322a22;
    --card-background: #322a22;
    --border: #322a22;
    --correct: #2e3440;
    --correct-background: #beb55b;
    --incorrect: #2e3440;
    --incorrect-background: #cb6077;
    --comment: #2e3440;
    --comment-background: #f4bc87;
    --sidebar: #eceff4;
    --input: #eceff4;
    --input-background: #594c3d;
  }