.card {
  font-size: 1em;
  line-height: 1.5em;
  color: var(--text);
  background-color: var(--card-background);
  display: flex;
  flex-direction: column;
  margin: 16px;
  border: 1px solid var(--border);
  min-width: fit-content;
}

.card > * {
  padding: .5rem;
  font-size: inherit;
  line-height: inherit;
}
  
  .card-header {
    display: flex;
    flex-direction: row;
  }
  
  .card-question {
    flex-grow: 1;
    border: 1px solid var(--border);
  }
  
  .card-score {
    text-align: center;
    width: 4em;
    border: 1px solid var(--border);
  }
  
  .card-answer-neutral {
    color: var(--text-background);
    background-color: var(--text);
    display: none;
    border: 1px solid var(--border);
  }

  .card-answer-correct {
    color: var(--correct);
    background-color: var(--correct-background);
    display: none;
    border: 1px solid var(--border);
  }
  
  .card-answer-wrong {
    color: var(--incorrect);
    background-color: var(--incorrect-background);
    display: none;
    border: 1px solid var(--border);
  }
  
  .card-comment {
    color: var(--comment);
    background-color: var(--comment-background);
    display: none;
    border: 1px solid var(--border);
  }
  
  .card img {
    vertical-align: middle;
  }