@import url("./themes.css");

.sidebar {
  background-color: var(--header-background);
  color: var(--sidebar);
  position: absolute;
  left: -405px;
  top: 60px;
  bottom: 0;
  z-index: 100;
  transition: left 150ms ease-in-out;
  box-shadow: 0px 10px 5px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.sidebar.opened {
  left: 0;
}

.sidebar a {
  width: 100%;
  display: block;
  color: var(--sidebar);
  text-decoration: none;
  width: 100%;
  padding: 12px;
  padding-right: 16px;
}

.sidebar a:hover {
  background-color: #00000088;
  text-decoration: none;
  cursor: pointer;
}

.sidebar-bottom {
  margin-top: auto;

  display: flex;
  flex-direction: column;
}

.sidebar-select {
  background-color: var(--input-background);
  color: var(--input);
  border: none;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  margin: 8px;
}
