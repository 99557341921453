

* {
  box-sizing: border-box;
  transition: color 100ms ease-in-out, background-color 100ms ease-in-out;
}

body {
  background-color: var(--page-background);
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--page-background);
  top: 60px;
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

.backdrop-item {
  color: var(--text);
  background-color: var(--text-background);
  padding: 0px 20px;
  margin-left: -20px;
  line-height: 1.5;
  overflow-wrap: normal;
  display: inline-block;
}